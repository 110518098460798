/* Pages */

/* Header */
const HEADER_TEXT_1 = ["Start", "Start"]
const HEADER_TEXT_2 = ["O Nas", "About Us"]
const HEADER_TEXT_3 = ["Almanach", "Almanac"]
const HEADER_TEXT_4 = ["Współpraca", "Cooperation"]
const HEADER_TEXT_5 = ["Kontakt", "Contact"]
export const HEADER_TEXTS = [
  HEADER_TEXT_1,
  HEADER_TEXT_2,
  HEADER_TEXT_3,
  HEADER_TEXT_4,
  HEADER_TEXT_5,
]

/* Index Hero */
export const HERO_TITLE = [
  "Fundacja Between.Pomiędzy",
  "Fundation Between.Pomiędzy",
]
export const HERO_TEXT = [
  "Między literaturą i teatrem",
  "Between Literature and Theatre",
]
export const HERO_BTN1 = ["Almanach", "Almanac", "almanac"]
//export const HERO_BTN1 = ["Aktualności", "News", 'news'];
export const HERO_BTN2 = ["Edukacja", "Education", "education/2021"]
export const HERO_BTN3 = [
  "Festiwal",
  "Festival",
  "festivals/",
  "attention-mark",
]
export const HERO_BTN4 = [
  "Festiwal Rozproszony",
  "Dispersed Festival",
  "festivals/14-festival",
]
export const HERO_BTN5 = ["Aktualności", "News", "news", "attention-mark"]

export const INDEX_CHAPT1 = ["Festiwale", "Festivals"]
export const INDEX_CHAPT1_DESCR = [
  "2020 & 2021 & 2022 & 2023",
  "2020 & 2021 & 2022 & 2023",
]
export const INDEX_CHAPT2 = ["Between Edukacja", "Between Education"]
export const INDEX_CHAPT2_DESCR = ["Projekty edukacyjne", "Education projects"]
export const INDEX_CHAPT3 = ["Publikacje", "Publications"]
export const INDEX_CHAPT3_DESCR = ["Nasze książki", "Our books"]
export const INDEX_CHAPT4 = ["Between Badania", "Between Research"]
export const INDEX_CHAPT4_DESCR = [
  "Badania przez nas prowadzone",
  "The research we are conducting",
]
export const INDEX_CHAPT5 = ["Between.Pomiędzy 2025", "Between.Pomiędzy 2025"]
export const INDEX_CHAPT5_DESCR = [
  "Teatr – Literatura – Zarządzanie",
  "Theatre – Literature – Management",
]

/* Footer */
const FOOTER_TEXT_1_1 = [
  "Fundacja Between.Pomiędzy",
  "Fundation Between.Pomiędzy",
]
const FOOTER_TEXT_2_1 = ["Kontakt", "Contact"]
const FOOTER_TEXT_2_2 = ["O Nas", "About Us"]
const FOOTER_TEXT_3_1 = ["Bądź z nami na:", "Follow us on:"]
export const FOOTER_COL1 = [FOOTER_TEXT_1_1]
export const FOOTER_COL2 = [FOOTER_TEXT_2_1, FOOTER_TEXT_2_2]
export const FOOTER_COL3 = [FOOTER_TEXT_3_1]

const FOOTER_FACEBOOK_LINK =
  "https://www.facebook.com/BETWEENPOMI%C4%98DZY-205476836132417/"
const FOOTER_FACEBOOK_NAME = "Facebook"
const FOOTER_INSTAGRAM_LINK = "https://www.instagram.com/between.pomiedzy/"
const FOOTER_INSTAGRAM_NAME = "Instagram"
const FOOTER_TWITTER_LINK = "https://twitter.com/back2sopot"
const FOOTER_TWITTER_NAME = "Twitter"
export const FOOTER_SOCIALS1 = [FOOTER_FACEBOOK_LINK, FOOTER_FACEBOOK_NAME]
export const FOOTER_SOCIALS2 = [FOOTER_INSTAGRAM_LINK, FOOTER_INSTAGRAM_NAME]
export const FOOTER_SOCIALS3 = [FOOTER_TWITTER_LINK, FOOTER_TWITTER_NAME]

/* MODAL */
// export const MODAL_IMAGE_SRC = "/images/photos/0_zdjecie_glowne_2025_modal.png"
export const MODAL_IMAGE_SRC = "/images/photos/plakaty_BP.png"
